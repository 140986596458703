import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {HangingMill} from './mill-file/mill-file-types'

export enum HangingOption {
  HANGING = 'hanging'
}

export class Hanging extends CabinetOption {
  public static readonly values = [
    'Vänster', 'Höger', 'Vänster-Vänster', 'Höger-Höger', 'Vänster-Höger',
    'Vänster-Höger x2',
    'Vänster-Vänster-Höger', 'Vänster-Höger-Höger', 'Upptill',
    'Vänster-Vänster-Höger x 2', 'Vänster-Höger-Höger x 2', 'FEL'
  ] as const

  constructor(option: HangingMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Hängning',
        name: HangingOption.HANGING,
        values: [...Hanging.values],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Describes if the door is left, right or top hinged'
    this.title = 'optHanging'

    this.setValuesFromProdboardData(option)
    this.updateSettings()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Hanging'
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.updateSettings()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    const selectionAsKey = this.viewOptions[0].selection
      .replace(/[åäö\s]/g, '_')
      .replace(/_x_2/g, '_x2')
      .toLowerCase()
    return [this.settingOption.getI18n(selectionAsKey, useCase, lc)]
  }

  private setValuesFromProdboardData(option: HangingMill): void {
    // Since we don't have product when mapping MillFile data, we need to do
    // another special mapping here for this Hanging option, that will depend
    // on how many doors the cabinet has, and if it has one or two rows of
    // doors ('x2' in the product code indicates there are two rows).
    const valuesMap: Record<number, {
      oneRow: Partial<Record<typeof HangingMill.values[number], typeof Hanging.values[number]>>
      twoRow: Partial<Record<typeof HangingMill.values[number], typeof Hanging.values[number]>>
    }> = {
      0: {
        // There are no cabinets with 0 doors and any row.
        oneRow: {},
        twoRow: {}
      },
      1: {
        oneRow: {
          undef: 'FEL',
          top: 'Upptill',
          left: 'Vänster',
          right: 'Höger'
        },
        // There are no cabinets with 1 door and two rows.
        twoRow: {}
      },
      2: {
        oneRow: {
          undef: 'Vänster-Höger',
          top: 'Upptill',
          left: 'Vänster-Höger',
          right: 'Vänster-Höger'
        },
        twoRow: {
          left: 'Vänster-Vänster',
          right: 'Höger-Höger'
        }
      },
      3: {
        oneRow: {
          undef: 'FEL',
          top: 'Upptill',
          left: 'Vänster-Vänster-Höger',
          right: 'Vänster-Höger-Höger'
        },
        // There are no cabinets with 3 doors and two rows.
        twoRow: {}
      },
      4: {
        // There are no cabinets with 4 doors and one rows.
        oneRow: {},
        twoRow: {
          left: 'Vänster-Höger x2',
        },
      },
      6: {
        // There are no cabinets with 6 doors and one rows.
        oneRow: {},
        twoRow: {
          undef: 'FEL',
          left: 'Vänster-Vänster-Höger x 2',
          right: 'Vänster-Höger-Höger x 2'
        }
      }
    }

    const rows = this.product.pc.includes('x2') ?
      'twoRow' : 'oneRow'
    this.viewOptions[0].selection = valuesMap[this.product.nuDo][rows][option.value]
  }

  private updateSettings(): void {
    this.active = this.viewOptions[0].selection !== ''
  }
}
