import {Observable, of} from 'rxjs'
import {first, map, switchMap, tap} from 'rxjs/operators'
import {Appliance, IAppliance} from '../appliances/model/appliance'
import {Comment} from '../comments/model/comment'
import {CounterTop, ICounterTop} from '../counter-top/model/counter-top'
import {CustomerStateMap} from '../customer/customer-state-map'
import {CustomerProject} from '../customer/model/customer-project.class'
import {Customer} from '../customer/model/customer.class'
import {
  FactoryExtraFactory
} from '../factory-extras/model/factory-extra-factory'
import {IProjectImage} from '../images/model/project-image'
import {CabinetOption} from '../model/cabinet-option'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {ProdboardFactory} from '../model/prodboard-factory'
import {PhaseTag} from '../model/tags/phase-tag'
import {ITag, PHASE_TAG_ID} from '../model/tags/types'
import {WarningService} from '../warnings/service/warning.service'
import {IOpenProjectChange, OpenProjectService} from './open-project.service'
import {Problem, ProblemService} from './problem.service'
import {IProjectFile} from './project-file-types'
import {ProjectPricingUtils} from './project-pricing.utils'
import {IProject} from './project-types'
import {SettingsItemService} from './settings-item.service'
import {TagService} from './tag.service'
import {ApplianceService} from '../appliances/service/appliance.service'

export class OpenProjectProcessor {

  constructor(
    private openProjectService: OpenProjectService,
    private tagService: TagService,
    private warningService: WarningService,
    private settingsItemService: SettingsItemService,
    private applianceService: ApplianceService,
    private prodboardFactory: ProdboardFactory,
    private problemService: ProblemService
  ) {
  }

  public processOpenProjectResponse(
    changes: IOpenProjectChange,
    project: IProject,
    file: IProjectFile,
    customer: Customer | null,
    customerProject: CustomerProject
  ): Observable<[IProject, IProjectFile, ProdboardCabinet[], Customer | null, CustomerProject]> {
    return of(project)
      .pipe(
        // Initialise Project values and add file values into it too.
        //  -> Only if Project or Prodboard file have changed.
        tap(() => {
          if (changes.projectChange || changes.fileChange) {
            project = this.parseProjectFromServer(project)
          }
        }),
        // Process CustomerProject
        //  -> Only if CustomerProject has changed.
        tap(() => {
          if (changes.customerProjectChange) {
            this.processCustomerProject(customerProject)
          }
        }),
        // Process Prodboard file and return Cabinets.
        //  -> Only if Prodboard file has changed.
        switchMap(() => {
          if (changes.fileChange) {
            return this.processProdboardFile(project, file)
          } else {
            return this.openProjectService.cabinets$.pipe(first())
          }
        }),
        // Process Cabinets
        //  -> Only if Project or Prodboard file have changed.
        map((cabinets: ProdboardCabinet[]) => {
          if (changes.projectChange || changes.fileChange) {
            return this.processCabinetsFromFile(project, cabinets)
          } else {
            return cabinets
          }
        }),
        // With all needed values, calculate project stats, and emit subjects
        //  -> Only if Project or Prodboard file have changed.
        tap((cabinets: ProdboardCabinet[]) => {
          if (changes.projectChange || changes.fileChange) {
            this.analyseAndGenerateWarnings(project, cabinets)
            project = this.calculateProjectStats(project, cabinets)
          }
        }),
        // Return all variables, now processed
        map((cabinets: ProdboardCabinet[]) => [
          project, file, cabinets, customer, customerProject
        ])
      )
  }

  public postProcessOpenProject(
    project: IProject,
    customerProject: CustomerProject
  ) {
    // Do not do any post-processing if any of the data is null
    if (!project || !customerProject) {
      return
    }

    // Update CustomerProject state
    customerProject.updateState()
    // Check possible updates on Project tags that depend on CustomerProject.
    // If there is any change, it will trigger a change in
    // tagService.tagChanged$, which we're listening to in open-project service.
    this.tagService.checkCustomerProject(customerProject, project).subscribe()
  }

  private processProdboardFile(project: IProject, file: IProjectFile): Observable<ProdboardCabinet[]> {
    if (file.plan?.items?.length > 0) {
      return this.prodboardFactory.createCabinets(file, project, this.openProjectService.cabinetsGroups)
    } else {
      // If there is no items to transform into Cabinets, we return an empty
      // array. Processing must continue.
      return of([])
    }
  }

  private processCabinetsFromFile(project: IProject, cabinets: ProdboardCabinet[]): ProdboardCabinet[] {
    // Reset warnings on the first place
    this.warningService.reset()

    if (cabinets.length) {
      // Re-order cabinets
      this.reorderCabinets(project, cabinets)
      // Update all cabinets with project values
      this.updateCabinetsWithProjectValues(project, cabinets)
      // Update cabinets with other cabinets (layout, lighting, etc.)
      this.updateCabinetsWithOtherCabinets(cabinets)
      // Set those with Prodboard comments in a signal
      this.openProjectService.cabinetsWithPbComments.set(cabinets
        .filter((cabinet: ProdboardCabinet) =>
          cabinet.prodboardComment.comment &&
          cabinet.prodboardComment.deleted === false))
      // Return Cabinets array, now processed and updated with project values
      return cabinets
    } else {
      // If there is no cabinets to analyse, we return an empty array.
      // Processing must continue.
      return []
    }
  }

  private processCustomerProject(
    customerProject: CustomerProject
  ) {
    customerProject.updateState()
  }

  private analyseAndGenerateWarnings(
    project: IProject,
    cabinets: ProdboardCabinet[]
  ) {
    // First, analyse all cabinets
    cabinets.forEach(c =>
      this.warningService.analyzeCabinet(c))
    // Then, analyse project
    this.warningService.analyzeProject(project)
    // Finally, generate warnings
    this.warningService.generateWarnings()
  }

  /**
   * Transforms interfaces into classes, creating "real objects" for:
   * Appliances, Counter Tops, Factory Extas and Tags.
   * It also initialises some variables that depend on these classes:
   * projectPhase
   */
  private parseProjectFromServer(project: IProject): IProject {
    // Transform interfaces into classes, "real objects":
    project.appliances = project.appliances
      .map((a: IAppliance) => new Appliance(a))
    project.counterTops = project.counterTops
      .map((c: ICounterTop) => new CounterTop(c))

    // Convert FactoryExtras into real factory-extra classes like Door, etc.
    project.factoryExtras = project.factoryExtras
      .map(f => FactoryExtraFactory.getExtra(f))

    // Convert Tags into real tag classes like PhaseTag, etc.
    // There must always be tags, even if an empty array
    project.tags = project.tags || []
    project.tags = TagService.createTags(project.tags, project)

    // Project phase is obtained from PhaseTag or else, it will be initial one.
    project.projectPhase = project.tags
        .find((t: ITag): t is PhaseTag => t.id === PHASE_TAG_ID)?.state
      || CustomerStateMap.FIRST_STATE_LABEL

    // Run possible migrations in project
    this.runMigrations(project)

    return project
  }

  private runMigrations(project: IProject) {
    /**
     * Migration for old images that was named "BLUEPRINT"??
     */
    project.images
      .filter((i: IProjectImage) => i.scope === 'BLUEPRINT' as any)
      .forEach((i: IProjectImage) => i.scope = 'PROJECT')

    /**
     * Migration from single adjustment to an array of adjustments
     */
    if (project.form.priceAdjustment) {
      project.form.priceAdjustments = [project.form.priceAdjustment]
      delete project.form.priceAdjustment
    }
  }

  /**
   * Whenever 'something' changes, this method is called. It looks at the
   * 'cabinets' and makes some additional calculations.
   *
   * It just updates "this.project" and hopes that all understans what
   * is going on... stupid shit!
   *
   * I hereby add a "receipt" property to the mess.
   *
   */
  private calculateProjectStats(project: IProject, cabinets: ProdboardCabinet[]): IProject {
    // Set approximate delivery date from Project
    this.openProjectService.approximateDeliveryDate
      .set(new Date(project.form.approxDeliveryDate).getTime())

    // Calculate project pricing using Project, Cabinets and appliance suppliers
    const applianceSuppliers = this.settingsItemService
      .getSettingConfig('SUPPLIER').suppliers
    const millColours: string[] = Array.from(this.settingsItemService
      .getSettingConfigAsMap('COLOR').values())
    project.pricing = ProjectPricingUtils
      .getProjectPricing(millColours, applianceSuppliers, project, cabinets)

    // Calculate project lighting using Project and Cabinets

    // Return the new updated project
    return project
  }

  /**
   * Slightly bizarre function that tries to convert an array of cabinets
   * with a potential UUID to our project cabinets form
   * so
   * cabinets = [ {
   *   uid: 'abc'
   *   index: 2
   * },{
   *   uid: 'cde'
   *   index: 4
   * }
   *
   * Project is {
   *   2: { uid: 'abc', index: 2},
   *   4: { uid: 'cde', index: 4}
   * }
   *
   * Trick is to make sure that the project object matches the array.
   * We should refactor this, but I do not have the time
   */
  private reorderCabinets(
    project: IProject,
    cabinets: ProdboardCabinet[]
  ): void {
    const projectCabinets: Record<string, any> = project.cabinets

    // Create a new one to set properly, this will become
    // The cabinets on the project
    const newCabs: { [key: string]: any } = {}

    cabinets.forEach((cabinet: ProdboardCabinet) => {
      // Shorthands so that we do _not_ forget to _not_ overwrite these.
      const index = cabinet.index
      const uid = cabinet.uid

      // If the new Cabinet has uid we go for UID approach
      if (cabinet.uid) {
        const existingProjectCabinetKey = Object.keys(projectCabinets).find((key: string) => {
          // If the old cabinet has uid, use that for comparison,
          // if not then use classic index comparison
          if (projectCabinets[key]?.uid) {
            return projectCabinets[key].uid === uid
          }
          return +key === index
        })
        if (existingProjectCabinetKey) {
          newCabs[cabinet.index] = {comments: [], ...projectCabinets[existingProjectCabinetKey]}
        } else {
          newCabs[index] = {comments: []}
        }
        // All future cabinets will have uid.
        newCabs[cabinet.index].uid = uid
      } else {
        newCabs[index] = {comments: [], ...projectCabinets[index]}
      }
    })
    // First we must remove all keys that do not exist in the new object
    // but do in the old.
    Object.keys(projectCabinets).forEach((k: string) => {
      if (!newCabs[k]) {
        delete projectCabinets[k]
      }
    })

    // This we do to make sure all cabinets exist in the cabs object.
    Object.keys(newCabs)
      .forEach((key: string) =>
        project.cabinets[key] = project.cabinets[key] || {comments: []})

    // Here we assign values to the existing object.
    // If we remove it the application explodes b/c the object
    // is referenced like everywhere :-p .
    Object.keys(newCabs)
      .forEach((key: string) => project.cabinets[key] = newCabs[key])

    // Now make sure all comments have the proper index
    Object.keys(projectCabinets)
      .forEach(k => {
        projectCabinets[k].comments
          .forEach((ck: Comment) => ck.cabinetIndex = +k)
      })
  }

  /**
   * Updates cabinets with project values. This includes the cabinet itself
   * and all its settings. It will do this per cabinet:
   *  1. Update their values with project-cabinet values
   *  2. Update their settings with project-cabinet settings
   *  3. Set & update assigned and recommended appliances
   *  4. Update "hasRecommendedAppliances" flag
   */
  private updateCabinetsWithProjectValues(
    project: IProject,
    cabinets: ProdboardCabinet[]
  ): void {
    // Per-cabinet, we will do:
    // 1. Update their values with project-cabinet values
    // 2. Update their settings with project-cabinet settings
    // 3. Set & update assigned and recommended appliances
    // 4. Update "hasRecommendedAppliances" flag
    cabinets.forEach((cabinet: ProdboardCabinet) => {
      // 1. Update their values with project-cabinet values
      cabinet.update(project.cabinets[cabinet.index])
      // 2. Update their settings with project-cabinet settings
      cabinet.setSettings(project.cabinets[cabinet.index].settings)
      // 3. Set & update assigned and recommended appliances
      cabinet.setAppliance(project.appliances)
      cabinet.updateAppliance(this.getCurrentAppliances())
      // 4. Update "hasRecommendedAppliances" flag
      cabinet.updateHasRecommended()
    })
  }

  /**
   * There is some logic that must be done once all cabinets have been updated
   * and not before. Complex but needed, since cabinets depend on others.
   *  - Set cabinet's neighbours using layouts
   *  - Reduce/calculate lightning sharing between cabinets
   *  - Update all cabinet problems
   * @private
   */
  private updateCabinetsWithOtherCabinets(cabinets: ProdboardCabinet[]) {
    // For each cabinet we iterate all layouts over all layouts for some nice
    // n^2 complexity!
    // Introduced for layout (neighbors) but can be generalized another day for
    // other cross cabinet things.
    cabinets.forEach(c => {
      const cabinetLayouts = cabinets
        .map(cc => cc.layout)
      c.layout.setNeighbours(cabinetLayouts)
    })

    // Iterate again all cabinets to adjust more things:
    // 1. Lightning sharing
    // 2. Send all cabinet-option problems to ProblemService
    //  2.1 Remove all problems after being sent
    cabinets.forEach(cabinet => {
      // 1. Calculate Lighting sharing
      cabinet.lighting.calculateSharing(cabinets)
      // 2. Send all cabinet-option problems to ProblemService
      cabinet.options.forEach((option: CabinetOption) => {
        option.problems.forEach((problem: Problem) => {
          this.problemService.problems$.next(problem)
        })
        // 2.1 Remove all problems after being sent
        option.problems.length = 0
      })
    })
  }

  private getCurrentAppliances(): Appliance[] {
    let appliances: Appliance[] = []
    this.applianceService.appliances$.pipe(first()).subscribe(value => {
      appliances = value
    })
    return appliances
  }
}
