
export const UniqueCabinets = [
  'All',
  'BD1',
  'BD1L1',
  'BL2',
  'BL3',
  'BL2L2',
  'BL4',
  'BL5',
  'BD2',
  'BD2L1',
  'BD2L2',
  'BD2L3',
  'BL3L4',
  'BD2L4',
  'BD3L3',
  'BL6',
  'BL8',
  'BL10',
  'BC',
  'BCD2',
  'BCP',
  'BSP1L1',
  'BSP2L2',
  'BSP1L1S40',
  'BSP1L1S60',
  'BSP2L2S80',
  'BD1o',
  'BL1o',
  'BD1o+PC',
  'BL1o+PC',
  'BD1dw-600',
  'BD1dw-450',
  'BD1wg',
  'BWnd',
  'BL9',
  'BL14',
  'BL16',
  'BIS1L1',
  'BIS1L2',
  'BIS1L3',
  'BISF',
  'BIS1L1-oak',
  'BIS1L2-oak',
  'BIS1L3-oak',
  'BISF-oak',
  'BbL1',
  'BbL2',
  'BbL3',
  'OD1',
  'OD1x2',
  'OD2',
  'OD2x2',
  'OD3',
  'OD3x2',
  'OD1C',
  'OD1C-tallx2',
  'ONdEx',
  'TD1',
  'TDx2',
  'TD1L2',
  'TD1L3',
  'TD2',
  'TD1C-tall',
  'TD1C-xtall',
  'TWDx2',
  'TWD2L2',
  'TWD1',
  'ToDx2',
  'ToD1L2',
  'ToD1L3',
  'TomD1L2',
  'TomDx2',
  'TmDx2',
  'TmD1L3',
  'TNd',
  'TShNd',
  'OSH',
  'PR',
  'OL1',
  'OL2',
  'OL3',
  'OL1x2',
  'OL2x2',
  'OL3x2',
  'CT-oak',
  'CT-oak-p',
  'AS-ash',
  'AS-ash-p',
  'PP-pin',
  'PP-pin-p',
  'DSU',
  'DSU1P',
  'DMU1P',
  'DLU1P',
  'DSUH',
  'DSU1PH',
  'DMU1PH',
  'DLU1PH',
  'DSP',
  'DSP1P',
  'DMP1P',
  'DLP1P',
  'DSPH',
  'DSP1PH',
  'DMP1PH',
  'DLP1PH'
] as const

export type CabinetType = typeof UniqueCabinets[number] | string

export const AllCabinetTypes: CabinetType[] = [
  ...UniqueCabinets
] as const

export interface SpecificCategory {
  name: string
  isActive: boolean
}

export interface SpecificCabinetType {
  cabinetType: CabinetType
  isMultiple?: boolean
  isIncluded?: boolean
  specificCategories?: SpecificCategory[]
}

export interface TableItem {
  group: string
  cabinetType: string
  isMultiple: boolean
  isIncluded: boolean
  specificCategories?: SpecificCategory[]
}
