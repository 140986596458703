import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'formatGroup'
})
export class FormatGroupPipe implements PipeTransform {
  transform(group: string[]): string {
    return group
      .slice(1)
      .filter((item) => item.trim() !== '')
      .map((item) =>
        item
          .split('_')
          .map((word, index) =>
            index === 0
              ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              : word.toLowerCase()
          )
          .join(' ')
      )
      .join(', ')
  }
}
