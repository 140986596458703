import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {FactoryData} from './factory/factory-data'
import {CombinedUnitMill} from './mill-file/mill-file-types'

export enum CombinedUnitOption {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bot'
}

export class CombinedUnit extends CabinetOption {
  constructor(option: CombinedUnitMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 30
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till vänster',
        name: CombinedUnitOption.LEFT,
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till höger',
        name: CombinedUnitOption.RIGHT,
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp över',
        name: CombinedUnitOption.TOP,
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp under ',
        name: CombinedUnitOption.BOTTOM,
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.active = false
    this.description = 'Some cabinets are combined into a larger unit'
    this.title = 'optCombinedUnit'
    this.setValuesFromProdboardData(option)
    this.setActive()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CombinedUnit'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.setActive()
  }

  public getFactoryData(): FactoryData {
    return {
      combined: {
        // Be careful here! Order matters
        left: this.viewOptions[0].selection === 'Ja',
        right: this.viewOptions[1].selection === 'Ja',
        top: this.viewOptions[2].selection === 'Ja',
        bottom: this.viewOptions[3].selection === 'Ja'
      }
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return this.viewOptions
      .filter((opt: ViewOption) => opt.selection === 'Ja')
      .map((opt: ViewOption) =>
        this.settingOption.getI18n(opt.name, useCase, lc))
  }

  private setValuesFromProdboardData(option: CombinedUnitMill): void {
    this.viewOptions.forEach((opt: ViewOption) =>
      opt.selection = option[opt.name])
  }

  private setActive(): void {
    this.active = this.viewOptions.some((opt: ViewOption) => opt.selection === 'Ja')
  }
}
