import { Pipe, PipeTransform } from '@angular/core'
import { ApplianceTypeMap } from '../../appliances/model/appliance'
import { TApplianceItemKey } from '../../appliances/model/appliance-map'
@Pipe({
  name: 'translationApplianceGroups',
})
export class TranslationApplianceGroupsPipe implements PipeTransform {
  transform(groups: TApplianceItemKey[]): (string | TApplianceItemKey)[] {
    return groups.map(group => ApplianceTypeMap.get(group) || group)
  }
}
