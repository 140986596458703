import {Injectable, signal, WritableSignal} from '@angular/core'
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject, shareReplay} from 'rxjs'
import {debounceTime, filter, first, map, switchMap, tap} from 'rxjs/operators'
import {Appliance, IRecommendedApplianceGroup} from '../appliances/model/appliance'
import {ApplianceService, IApplianceService} from '../appliances/service/appliance.service'
import {CounterTop, ICounterTop, ICounterTopCabinet} from '../counter-top/model/counter-top'
import {CustomerProject} from '../customer/model/customer-project.class'
import {Customer} from '../customer/model/customer.class'
import {CustomerService} from '../customer/service/customer.service'
import {StateService} from '../customer/service/state.service'
import {FactoryExtra} from '../factory-extras/model/factory-extra'
import {IProjectImage} from '../images/model/project-image'
import {ImagesService} from '../images/services/images.service'
import {CabinetSettings} from '../model/cabinet-settings/cabinet-setting'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {ProdboardFactory} from '../model/prodboard-factory'
import {TProjectItemType} from '../project-viewer/model/project-node'
import {WarningService} from '../warnings/service/warning.service'
import {OpenProjectProcessor} from './open-project.processor'
import {ProblemService} from './problem.service'
import {IProjectFile} from './project-file-types'
import {IProject, LanguageCodeCountryMap, ProjectFormData} from './project-types'
import {ProjectService} from './project.service'
import {SettingsItemService} from './settings-item.service'
import {TagService} from './tag.service'
import {AllCabinetTypes, CabinetType, SpecificCabinetType} from '../cabinet/model/cabinet-type'
import {CabinetGroupService} from './cabinet-group.service'
import {DefaultMap} from '../application/helpers'
import {TApplianceItemKey} from '../appliances/model/appliance-map'

export interface CabinetWithRecommendation {
  cabinet: ProdboardCabinet
  isRecommended: boolean
}

/**
 * The Cabinet Options that are emitted.
 */
export interface CabinetOptionChange {

  /**
   * Cabinet Id/index??
   */
  cabinet: number

  /**
   * This is the unique name/id of the option.
   */
  name: string

  /**
   * We do not care about the data as such.
   */
  data: any

  /**
   * Active is very special?
   */
  active: boolean
}

export interface IOpenProjectChange {
  projectChange?: boolean
  fileChange?: boolean
  customerProjectChange?: boolean
  customerChange?: boolean
}

@Injectable({
  providedIn: 'root'
})
/**
 * This service holds the information of those entities that should be present
 * whenever a project (aka kitchen project) is open. These entities are:
 * Project, Prodboard file, Cabinets, Customer and Customer project.
 * All of them are linked somehow to Project.
 *
 * This service is in charge of retrieving all needed information from server,
 * and listen to any changes on them. Changes can be performed through
 * {@link openProjectChanges$}. It should be the only way used outside
 * this service to trigger an entity save/update in API.
 *
 * For more specific information, read associated README file.
 *
 * It implements {@link IApplianceService} so it can be injected as
 * APPLIANCE_SERVICE in some places.
 */
export class OpenProjectService implements IApplianceService {

  /////////////////////////////////////////////////////////////////////////
  // Main observables that other component/services can listen to
  /////////////////////////////////////////////////////////////////////////

  /**
   * Current open Project (kitchen project)
   * Values are sent after being processed: calculated stats and pricing
   */
  public project$: Observable<IProject | null>
  /**
   * Current open project's ProdboardFile
   * Values are sent after being processed
   */
  public projectFile$: Observable<IProjectFile | null>
  /**
   * Current open project's Cabinets. They are calculated whenever there is
   * a change on Prodboard file.
   */
  public cabinets$: Observable<ProdboardCabinet[]>
  /**
   * Current open project's Customer, which can be null if not associated yet
   */
  public customer$: Observable<Customer | null>
  /**
   * Current open project's associated CustomerProject, which handles all
   * condition checkboxes/radio buttons
   */
  public customerProject$: Observable<CustomerProject | null>


  /////////////////////////////////////////////////////////////////////////
  // Helping signals as easy access to certain variables
  /////////////////////////////////////////////////////////////////////////

  /**
   * Expose the delivery date as that is needed in other places
   */
  public approximateDeliveryDate: WritableSignal<number> = signal(0)

  /**
   * All cabinets with Prodboard comments
   */
  public cabinetsWithPbComments: WritableSignal<ProdboardCabinet[]> = signal([])


  /////////////////////////////////////////////////////////////////////////
  // Other public features
  /////////////////////////////////////////////////////////////////////////

  /**
   * On this subject each option on each cabinet can emit changes
   * We populate the data sent to the project
   */
  public cabinetOptionsChanges$ =
    new ReplaySubject<CabinetOptionChange>(1)

  public recommendedAppliancesChanges$ =
    new ReplaySubject<IRecommendedApplianceGroup>(1)

  public openProjectChanges$: BehaviorSubject<IOpenProjectChange> =
    new BehaviorSubject<IOpenProjectChange>({})


  /////////////////////////////////////////////////////////////////////////
  // Private subjects to control main observables - Changes are only here
  /////////////////////////////////////////////////////////////////////////

  /**
   * Subject that stores current selected Project. It will send two events
   * every time a Project is retrieved/updated. Why? Once without calculations
   * like pricing and similar, and once with all calculations done.
   * This is necessary because we need first value to calculate second in
   * different functions, so we need to store this value somewhere.
   * However, when creating {@link project$} we make sure to just send events
   * from second Project value, the one with calculations.
   * @private
   */
  private pProject$: BehaviorSubject<IProject | null> =
    new BehaviorSubject<IProject | null>(null)
  private pProjectFile$: BehaviorSubject<IProjectFile | null> =
    new BehaviorSubject<IProjectFile | null>(null)
  private pCabinets$: BehaviorSubject<ProdboardCabinet[]> =
    new BehaviorSubject<ProdboardCabinet[]>([])
  private pCustomer$: BehaviorSubject<Customer | null> =
    new BehaviorSubject<Customer | null>(null)
  private pCustomerProject$: BehaviorSubject<CustomerProject | null> =
    new BehaviorSubject<CustomerProject | null>(null)
  private pCabinetsGroups$: BehaviorSubject<DefaultMap<string, SpecificCabinetType[]> | null> =
    new BehaviorSubject<DefaultMap<string, SpecificCabinetType[]> | null>(null)


  /////////////////////////////////////////////////////////////////////////
  // Private, local variables to keep easy-access on some of them
  /////////////////////////////////////////////////////////////////////////

  private processor: OpenProjectProcessor

  constructor(
    private settingsItemService: SettingsItemService,
    private projectService: ProjectService,
    private customerService: CustomerService,
    private stateService: StateService,
    private problemService: ProblemService,
    private warningService: WarningService,
    private imagesService: ImagesService,
    private cabinetGroupService: CabinetGroupService,
    private tagService: TagService,
    private applianceService: ApplianceService,
    private prodboardFactory: ProdboardFactory
  ) {
    this.cabinetGroupService.getCurrentGroupsCabinetsList()
    // Initialise all Observables from Subjects
    this.project$ = this.pProject$.asObservable()
    this.projectFile$ = this.pProjectFile$.asObservable()
    this.cabinets$ = this.pCabinets$.asObservable()
    this.customer$ = this.pCustomer$.asObservable()
    this.customerProject$ = this.pCustomerProject$.asObservable()

    // Create processor to use whenever new Project data is changed
    this.processor = new OpenProjectProcessor(
      this,
      this.tagService,
      this.warningService,
      this.settingsItemService,
      this.applianceService,
      this.prodboardFactory,
      this.problemService
    )

    // With every change event, there will be some actions to be done.
    this.initialiseChangesListener()
    // Listen to cabinet-option-change events
    this.initialiseCabinetOptionChangesListener()

    this.initialiseRecommendedAppliancesChangesListener()
    // With any change in Project or its dependencies, CustomerProject state
    // can change, which will trigger a save event for CustomerProject.
    this.initialiseCustomerProjectStateListener()
  }

  public get cabinetsGroups(): DefaultMap<string, SpecificCabinetType[]> | null {
    return this.pCabinetsGroups$.value
  }

  private get project(): IProject | null {
    return this.pProject$.value
  }

  private get projectFile(): IProjectFile | null {
    return this.pProjectFile$.value
  }

  private get customer(): Customer | null {
    return this.pCustomer$.value
  }

  private get customerProject(): CustomerProject | null {
    return this.pCustomerProject$.value
  }

  /**
   * Initial function to start the correct working behaviour of this service.
   * We need a Project to work all, and from it, File, Cabinets, Customer and
   * CustomerProject are retrieved too. It's dependencies.
   *
   * Once some variables are set, like project file, others will be initialised,
   * like cabinets, thanks to listeners that are set up in ngOnInit.
   *
   * If the project is already loaded in this service there will be no API
   * request.
   *
   * @param projectId Project ID to look for in server.
   */
  public selectProjectById(projectId: string): Observable<IProject> {
    // If we have the project that it's being asked for already "cached", there
    // is no need to recover it again. We'll use the one we have saved already
    if (this.project?.id === projectId) {
      return of(this.project)
    }
    // If not, we'll recover the latest version of the project and start
    // initialising all the rest of related values: File, Customer, etc.
    return this.projectService.getProject(projectId, '$LATEST')
      .pipe(
        switchMap((projectAndFile) => {
          // After recovering Project and Prodboard file, we'll recover the
          // next dependencies, Customer and Customer Project.
          return forkJoin([
            of(projectAndFile),
            this.getCustomerAndCustomerProject(projectAndFile[0])
          ])
        }),
        // Once we have all dependencies, we process the result
        switchMap((results: [[IProject, IProjectFile], [Customer, CustomerProject]]) =>
          this.processProjectAndDependencies(
            // We simulate that ALL has changed. It is an initial loading.
            {
              projectChange: true,
              fileChange: true,
              customerChange: true,
              customerProjectChange: true
            },
            results[0][0], results[0][1], results[1][0], results[1][1])),
        // Lastly, return our project$ subject, which will contain new Project
        switchMap(() => this.project$)
      )
  }

  /**
   * Whenever a project is closed, like when clicking "Mill" icon in header,
   * then this function should be called, to save current unsaved changes and
   * clear all variables in this service. A reset.
   */
  public unselectProjectAndReset(): void {
    this.saveCurrentSelectionsAndClearVariables()
  }

  /**
   * Sets a new bunch of data into Project's form value.
   *
   * Also, as special case, it will update Customer's country value if
   * Project's form "lc" value has changed. This is a thing that KM wants.
   * Basically, "country" field in Customer is "useless", it is a copy of
   * the value in Project Data tab (Mill > Project > Project data).
   * @param data
   */
  public setProjectForm(data: ProjectFormData): Observable<IProject> {
    // Create safety net to ensure that project is existent
    return this.project$.pipe(
      first(),
      filter(Boolean),
      map(project => {
        // Set all form data blindly, and then customer name separately
        Object.assign(project.form, data)
        project.customer.name = data.customerName ||
          project.customer.name

        // If "country" is changed in Project's form, it needs to modify Customer's
        // "country" too.
        let shouldModifyCustomer: boolean = false
        if (this.customer && project.form.lc &&
          this.customer.country?.toLowerCase() !==
          LanguageCodeCountryMap[this.project.form.lc].toLowerCase()) {
          // Update current customer with new country.
          CustomerService.setCountryToCustomer(
            this.customer, LanguageCodeCountryMap[project.form.lc])
          shouldModifyCustomer = true
        }

        this.openProjectChanges$.next({
          projectChange: true,
          customerChange: shouldModifyCustomer
        })

        // Return updated project
        return project
      })
    )
  }

  /**
   * Retrieves a cabinet by its index from the list of cabinets.
   * The index is 1-based, so it adjusts by subtracting 1 to access the correct element in the array.
   * @param cabinetIndex - The index of the cabinet to retrieve (1-based).
   * @returns The cabinet at the specified index.
   */
  public getCabinetByIndex(cabinetIndex: number): ProdboardCabinet {
    const cabinets = this.pCabinets$.value

    return cabinets[cabinetIndex - 1]
  }

  // TODO - This method is breaking the Observable protection and prevents updates of Cabinets
  //  in its implementations. It should not be used like this.
  //  Correctly subscribe to the observable in the implementation instead of doing this.
  public getCabinets(): ProdboardCabinet[] {
    return this.pCabinets$.value
  }

  public getCabinetById(uid: string): ProdboardCabinet {
    const cabinets = this.pCabinets$.value
    return cabinets.find(cabinet => cabinet.uid === uid)
  }

  public getCabinetsByCat(cat: CabinetType): ProdboardCabinet[] {
    const cabinets = this.pCabinets$.value
    return cabinets.filter(c => c.cat === cat || cat === 'All')
  }


  public getRecommendedCabinetMap(appliance: Appliance): CabinetWithRecommendation[] {
    const cabinets: ProdboardCabinet[] = this.getCabinets()

    const tagKey = appliance.applianceTree.join('-')

    const specificTypes = this.cabinetsGroups.get(tagKey)

    return cabinets.map(cabinet => ({
      cabinet,
      isRecommended: specificTypes.some((specificType: SpecificCabinetType) =>
        this.isCabinetRecommended(cabinet, appliance, specificType)
      )
    }))
  }

  public getRecommendedCabinets(appliance: Appliance): ProdboardCabinet[] {
    const tagKey = appliance.applianceTree.join('-')

    const specificTypes = this.cabinetsGroups.get(tagKey)
    if (!specificTypes) {
      return []
    }

    const cabinets = specificTypes.flatMap((specificType: SpecificCabinetType) => {
      const cabinets = this.getCabinetsByPartOfCatOrCat(specificType.cabinetType)
      return cabinets.filter((cabinet: ProdboardCabinet) => this.isCabinetRecommended(cabinet, appliance, specificType))
    })

    return Array.from(new Set(cabinets))
  }

  private isCabinetRecommended(
    cabinet: ProdboardCabinet,
    appliance: Appliance,
    specificType: SpecificCabinetType
  ): boolean {
    const isSameParent = appliance.parentCabinetUid === cabinet.uid
    const hasMatchingAppliance = cabinet.cabinetAppliances.some(cabinetAppliance => cabinetAppliance.name === appliance.name)
    const hasValidCategories = !specificType.specificCategories?.length || cabinet.isValidSpecificCategories(specificType.specificCategories)

    return isSameParent || (!hasMatchingAppliance && hasValidCategories)
  }


  public getCabinetTitleById(uid: string): string {
    const cabinet: ProdboardCabinet = this.getCabinetById(uid)
    return cabinet.index + '. ' + cabinet.cat
  }

  public getCabinetsByPartOfCatOrCat(partOfCat: string): ProdboardCabinet[] {
    return AllCabinetTypes.filter(cat => cat.startsWith(partOfCat)).flatMap(cat => this.getCabinetsByCat(cat))
  }

  // TODO - This method is breaking the Observable protection and prevents updates of Project
  //  in its implementations. It should not be used like this.
  //  Correctly subscribe to the observable in the implementation instead of doing this.
  public getProject(): IProject {
    return this.pProject$.value
  }

  /**
   * Set's a new Customer to project, linking it by ID.
   * Once it is set, it will trigger a change-event to update Project.
   * @param customerId New Customer's ID to be linked in Project.
   */
  public setCustomerToProject(customerId: string) {
    // Make sure we have a project selected. Safety net.
    return this.project$
      .pipe(
        first(),
        filter(Boolean),
        switchMap(() => {
          // Update Customer and trigger a save event
          this.project.customerId = customerId
          // Immediately save this change. It is an important one
          return this.saveOpenProjectBasedOnChanges({projectChange: true})
        })
      )
  }

  public forceProjectUpdate() {
    return this.projectService.getProject(this.pProject$.value.id, '$LATEST')
      .pipe(
        switchMap(([{version}]) => {
          const projectWithLatestVersion = {...this.pProject$.value, version: version}

          return this.projectService.updateProject(projectWithLatestVersion, true)
            .pipe(
              tap((updatedProject) => this.pProject$.next(updatedProject))
            )
        })
      )
  }

  /**
   * Function that will trigger different item save/updates. Depending on which
   * flags are active it can update Project, Prodboard file or Customer Project.
   *
   * Whenever calls are finished, the corresponding subject will get an update,
   * like {@link project$} or {@link customerProject$}.
   * @param changes Object with different flags that indicates what to update
   * @param avoidProcessing Flag that if true will stop the processing of
   * update result. Typically used whenever you are saving changes right
   * before closing the open project.
   */
  public saveOpenProjectBasedOnChanges(
    changes: IOpenProjectChange,
    avoidProcessing: boolean = false
  ) {
    // Create an array of Observables with either the corresponding updating
    // function (if their changes' flag is active) or their current value
    // (using of() to create an Observable with that value).
    const projectChangedWithoutFile = changes.projectChange && !changes.fileChange
    const projectAndFileUpdate$ = changes.fileChange
      ? this.updateFile$(this.projectFile)
      : of([this.project, this.projectFile])

    const observables$: Observable<any>[] = [
      projectChangedWithoutFile
        ? this.projectService.updateProject(this.project)
        : changes.fileChange
          ? projectAndFileUpdate$.pipe(map(([updatedProject]) => updatedProject))
          : of(this.project),
      changes.fileChange
        ? projectAndFileUpdate$.pipe(map(([, projectFile]) => projectFile))
        : of(this.projectFile),
      changes.customerChange
        ? this.customerService.saveCustomer(this.customer)
        : of(this.customer),
      changes.customerProjectChange
        ? this.customerService.saveCustomerProject(this.customerProject)
        : of(this.customerProject)
    ]

    // Wait for all responses to be done.
    return forkJoin(observables$)
      .pipe(
        // Reset changes. All has been saved now
        tap(() => {
          this.openProjectChanges$.next({
            projectChange: false,
            fileChange: false,
            customerChange: false,
            customerProjectChange: false
          })
        }),
        // Sometimes we don't want to process results, for example, after
        // pressing "Mill" icon in header (closing open Project).
        filter(() => !avoidProcessing),
        // Send all parameters to processing
        switchMap(([project, projectFile, customer, customerProject]: [IProject, IProjectFile, Customer, CustomerProject]) =>
          this.processProjectAndDependencies(changes, project, projectFile, customer, customerProject))
      )
  }

  private updateFile$(file: IProjectFile): Observable<[IProject, IProjectFile]> {
    return this.projectService.updateFile(file).pipe(
      switchMap((projectFile) => {
        const project: IProject = {...this.project, fileId: projectFile.id}

        return this.projectService.updateProject(project).pipe(
          map((updatedProject) => [updatedProject, projectFile] as [IProject, IProjectFile])
        )
      }),
      shareReplay(1)
    )
  }

  /////////////////////////////////////////////////////////////////////////
  // Project's file related functions
  /////////////////////////////////////////////////////////////////////////

  /**
   * Function that should be called whenever users are creating new projects,
   * and they select a file. Make sure to use this and not
   * {@link setNewProdboardFile}, since that other functions expects that a
   * real project is already created and currently open.
   *
   * Creates a temporal project with a given file, which will trigger a
   * creation of cabinets and a calculation of prices.
   * Why do we do this? So whenever a user is creating a new project they
   * can see a populated header with a "placeholder" of what it would be.
   *
   * @param file File that can come from server, local disk or empty.
   */
  public createTemporalProjectWithNewFile(file: IProjectFile) {
    // Create a new project and set in Subject
    const project = ProjectService.newProject()
    project.customer.name = file.customer.name
    // Do a response processing to update UI
    return this.processProjectAndDependencies(
      {projectChange: true, fileChange: true},
      project,
      file,
      null,
      null
    ).pipe(
      map(() => this.project)
    )
    // No need to send a "change" event since we cannot save anything yet.
    // This is just a temporal project so that users can see a nice header.
  }

  /**
   * Assigns a new Prodboard file to currently open Project.
   * However, it will not save the changes instantly, it will trigger a change
   * event instead. This way the UI is a bit prettier and clearer for users.
   * @param file New file that can come from server, local disk or empty.
   */
  public setNewProdboardFile(file: IProjectFile) {
    // We create a "safe net" here to ensure that this method is only called
    // whenever a project is already open, and it has a file already
    return this.project$
      .pipe(
        first(),
        filter(Boolean),
        map(() => this.projectFile),
        first(),
        filter(Boolean),
        // Because we are updating a file on DB, it must have the same
        // ID, qualities, specs (call it whatever), as the previous one.
        map((currentFile) =>
          Object.assign(currentFile, file)),
        // Emit a change event
        tap(() =>
          this.openProjectChanges$.next({
            projectChange: true,
            fileChange: true
          }))
      )
  }


  /////////////////////////////////////////////////////////////////////////
  // Add/Remove Appliances from project
  /////////////////////////////////////////////////////////////////////////

  public saveAppliance(appliance: Appliance): Observable<Appliance> {
    const exist = this.project.appliances
      .find((existing: Appliance) => existing.id === appliance.id)
    if (exist) {
      Object.assign(exist, appliance)
    } else {
      this.project.appliances.push(appliance)
    }
    this.openProjectChanges$.next({projectChange: true})
    return of(appliance)
  }

  public deleteAppliance(appliance: Appliance): Observable<void> {
    this.project.appliances = this.project.appliances.filter((a: Appliance) => a.id !== appliance.id)
    this.openProjectChanges$.next({projectChange: true})
    return of()
  }

  public deleteApplianceByCabinetId(uid: string): Observable<never> {
    this.project.appliances = this.project.appliances.filter((a: Appliance) => a.parentCabinetUid !== uid)
    this.openProjectChanges$.next({projectChange: true})
    return of()
  }


  /////////////////////////////////////////////////////////////////////////
  // Add/Update/Remove FactoryExtras from project
  /////////////////////////////////////////////////////////////////////////

  public addFactoryExtra(extra: FactoryExtra): FactoryExtra {
    extra.version++
    this.project.factoryExtras.push(extra)
    this.openProjectChanges$.next({projectChange: true})
    return extra
  }

  public saveFactoryExtra(extra: FactoryExtra): FactoryExtra {
    this.removeFactoryExtra(extra.id)
    return this.addFactoryExtra(extra)
  }

  public removeFactoryExtra(id: string): void {
    this.project.factoryExtras = this.project.factoryExtras.filter(f => f.id !== id)
    this.openProjectChanges$.next({projectChange: true})
  }

  public updateCurrentApplianceFilter(id: string, groups: TApplianceItemKey[][]): void {
    this.recommendedAppliancesChanges$.next({
      id,
      groups
    })
  }

  /////////////////////////////////////////////////////////////////////////
  // Add/Remove/Get/Set-to-cabinets CounterTops from project
  /////////////////////////////////////////////////////////////////////////

  public addCounterTop(counterTop: CounterTop): void {
    const existing = this.project.counterTops.find((ct: ICounterTop) => counterTop.id === ct.id)
    if (existing) {
      Object.assign(existing, counterTop)
    } else {
      this.project.counterTops.push(counterTop)
    }
    // There shall basically always be one or more cabinets.
    this.cabinets$.pipe(
      first(),
      switchMap((cabinets: ProdboardCabinet[]) => {
        // Make sure to always have something for ForkJoin to chew
        const res: Observable<any>[] = [of({})]

        // Create a flat array of valid UID:s [as-d-1, asd-12-, ...]
        const cabinetUIDs: string[] = counterTop.cabinets.map((c: ICounterTopCabinet) => c.uid)
        cabinets.forEach((c: ProdboardCabinet) => {
          // Remember if we have fiddled with this cabinet or not.
          let changed = false

          if (c.counterTopId === counterTop.id) {
            // We remove our self from this cabinet b/c we are not sure if it should be there
            c.counterTopId = ''
            // Remember that we have changed this cabinet and need to save it.
            changed = true
          }
          // If this is a cabinet with proper UID we add this counterTop
          if (cabinetUIDs.indexOf(c.uid) !== -1) {
            c.counterTopId = counterTop.id
            changed = true
          }

          if (changed) {
            // If we have changed this cabinet add it to change list
            // Copies the settings to the project
            res.push(this.setCabinetSettings(c, c.getSettings()))
          }
        })
        return forkJoin(res)
      })
    ).subscribe({
      next: () => {
        // Make sure to save even if no cabinets, rare.
        this.openProjectChanges$.next({projectChange: true})
      }
    })
  }

  public removeCounterTop(index: number): void {
    this.cabinets$.pipe(first()).subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        this.project.counterTops[index].cabinets.forEach((cab: ICounterTopCabinet) => {
          const cabToRemove = cabinets.find((cabinet: ProdboardCabinet) => cab.uid === cabinet.uid)
          if (cabToRemove) {
            cabToRemove.setSettings({counterTop: ''} as any)
          }
        })
        this.project.counterTops.splice(index, 1)
        this.openProjectChanges$.next({projectChange: true})
      }
    })
  }


  /////////////////////////////////////////////////////////////////////////
  // Update/Modify CabinetOptions of project's cabinets
  /////////////////////////////////////////////////////////////////////////

  /**
   * This sets settings from the outside (settings component) to
   * the cabinet and to the project for later saving
   */
  public setCabinetSettings(cabinet: ProdboardCabinet, settings: CabinetSettings): Observable<CabinetSettings> {
    // Create safety net to ensure that project is existent
    return this.project$.pipe(
      first(),
      filter(Boolean),
      map((project: IProject) => {
        // HEADS UP! This relies on cabinet index rather than UUID, should be fixed
        const projectCab = project.cabinets[cabinet.index]
        cabinet.setSettings(settings)

        if (!projectCab.settings) {
          projectCab.settings = new CabinetSettings()
        }

        Object.assign(projectCab.settings, settings)
        this.openProjectChanges$.next({projectChange: true})
        return settings
      }))
  }

  public resetSettings(cabinet: ProdboardCabinet): Observable<CabinetSettings> {
    // Create safety net to ensure that project is existent
    return this.project$.pipe(
      first(),
      filter(Boolean),
      switchMap((project: IProject) => {
        const projectCab = project.cabinets[cabinet.index]
        cabinet.resetSettings() // In case ?
        projectCab.settings = new CabinetSettings()
        return this.setCabinetSettings(cabinet, projectCab.settings)
      })
    )
  }


  /////////////////////////////////////////////////////////////////////////
  // Add/Remove/Move/Get images from project
  /////////////////////////////////////////////////////////////////////////

  public addImage(image: IProjectImage): void {
    this.project.images.push(image)
    this.openProjectChanges$.next({projectChange: true})
  }

  public removeImagesBySourceId(sourceId: string): void {
    this.project.images.filter(i => i.sourceId === sourceId)
      .forEach((i) => this.removeImage(i.id))
  }

  public removeImage(id: string): void {
    this.project.images = this.project.images.filter((i: IProjectImage) => i.id !== id)

    this.imagesService.deleteImage(id).subscribe()
    this.openProjectChanges$.next({projectChange: true})
  }

  /**
   * Move an image, set source and scope as appropriate
   * @param imageId - The ID of the IMAGE
   * @param sourceId - The SourceID, if any, mostly for comment images. If no scope pass null explicitly
   * @param scope - The scope, will always be set to the scope. Pass original scope if you do not want to change it
   */
  public moveImage(imageId: string, sourceId: string | null, scope: TProjectItemType): void {
    const image = this.project.images.find((i: IProjectImage) => i.id === imageId)
    if (image) {
      image.scope = scope
      image.sourceId = sourceId
      this.openProjectChanges$.next({projectChange: true})
    }
  }

  public getImage(id: string): Observable<IProjectImage> {
    let image: IProjectImage | undefined
    return this.project$.pipe(
      first(),
      filter(Boolean),
      switchMap((project: IProject) => {
        image = project.images.find(i => i.id === id)
        if (!image) {
          image = {
            scope: 'COMMENT',
            name: 'IMAGE MISSING',
            displayName: 'IMAGE_MISSING',
            viewUrl: './assets/photo_black.png',
            sourceId: '',
            title: 'IMAGE MISSING'
          }
          return of('/assets/photo_black.png')
        }
        return this.imagesService.getViewUrl(id)
      }),
      map((url: string) => {
        image.viewUrl = url
        return image
      })
    )
  }

  /////////////////////////////////////////////////////////////////////////
  // Private functions
  /////////////////////////////////////////////////////////////////////////

  private initialiseChangesListener() {
    // Whenever there is a new changes trigger, we will send a "false-saving
    // event" to update all subjects: project$, customer$, customerProject$,
    // cabinets$ and projectFile$.
    // Real "saving events" are sent by AutoSaveService
    this.openProjectChanges$
      .pipe(
        // We only care to do this if there is an actual change.
        filter(changes =>
          Object.keys(changes).some(key => changes[key] === true)),
        // Send all parameters to processing, which will refresh all UI
        switchMap((changes) =>
          this.processProjectAndDependencies(changes,
            this.project, this.projectFile, this.customer, this.customerProject))
      )
      .subscribe()
  }

  private initialiseCabinetOptionChangesListener() {
    this.cabinetOptionsChanges$
      .subscribe(change => {
        // Create safety net to ensure that project is existent
        this.project$
          .pipe(first(), filter(Boolean))
          .subscribe(() => {
            // First set the data in the project. It can be anything and will be put back
            change.data.active = change.active
            const existing = this.project?.cabinets[change.cabinet][change.name] || {}
            this.project.cabinets[change.cabinet][change.name] = change.data
            if (existing.comments) {
              this.project.cabinets[change.cabinet][change.name].comments = existing.comments
            }
            // Let the world know we have changes. And us too!
            // Changes calls update on the cabinet.
            this.openProjectChanges$.next({projectChange: true})
          })
      })
  }

  private initialiseRecommendedAppliancesChangesListener() {
    this.recommendedAppliancesChanges$
      .subscribe(recommendedApplianceGroup => {
        this.project$
          .pipe(first(), filter(Boolean))
          .subscribe(() => {
            const index = this.project.recommendedAppliances
              .findIndex(group => group.id === recommendedApplianceGroup.id)
            if (index !== -1) {
              this.project.recommendedAppliances[index] = recommendedApplianceGroup
            } else {
              this.project.recommendedAppliances.push(recommendedApplianceGroup)
            }
            this.openProjectChanges$.next({projectChange: true})
          })
      })
  }

  /**
   * Whenever "something" is modified (Customer, Project, CustomerProject or
   * Cabinets) it will need to re-configure StateService.
   * Once StateService is configured, it will check different properties and
   * decide if CustomerProject needs an update or not.
   * If it needs an update, it will trigger a change event to start the saving
   * process
   * @private
   */
  private initialiseCustomerProjectStateListener() {
    combineLatest([
      this.customerProject$,
      this.project$,
      this.cabinets$,
      this.customer$
    ])
      .pipe(
        // There are times that all subjects are updated at the same place,
        // so instead of triggering this 4 times, we just do it once.
        debounceTime(100),
        map((res) => {
          this.stateService.project = res[1]
          this.stateService.cabinets = res[2]
          this.stateService.customer = res[3]
          // The subscription here is dangerous, it might cause a loop.
          // If subsequent calls return true, this will go on forever!
          // Need to make som kind of check for that?!
          return res[0]
        }),
        // Only proceed if we have projects, customer and kitchen
        filter((cp: CustomerProject) =>
          !!cp && !!this.stateService.project),
        map((cp: CustomerProject) => {
          // Result is composed by the individual result of every processing
          // method. If any of them is true, result will be true.
          // It means "modify CustomerProject if any of the methods needs it".
          const res = [
            this.stateService.processConditions(cp),
            this.stateService.processApplianceAndCounterTopSuppliers(cp)
          ].reduce((result, acc) => acc || result, false)

          // Make sure the state is updated, otherwise the changes (if any) are
          // not reflected in the UI.
          cp.updateState()
          return res
        }),
        filter(Boolean)
      )
      .subscribe({
        next: () => {
          this.openProjectChanges$.next({customerProjectChange: true})
        }
      })
  }

  /**
   * Function that will receive Project and its dependencies to be processed.
   * Once all is done, it will assign the subject values.
   *
   * IMPORTANT!! This should be the only place in which we set all subjects.
   * @private
   */
  private processProjectAndDependencies(
    changes: IOpenProjectChange,
    project: IProject,
    projectFile: IProjectFile,
    customer: Customer | null,
    customerProject: CustomerProject
  ) {
    return this.processor.processOpenProjectResponse(
      changes, project, projectFile, customer, customerProject)
      .pipe(
        // Assign and emit all new subject values
        tap((result) => {
          this.pProject$.next(result[0])
          this.pProjectFile$.next(result[1])
          this.pCabinets$.next(result[2])
          this.pCustomer$.next(result[3])
          this.pCustomerProject$.next(result[4])
        }),
        tap(() => this.processor
          .postProcessOpenProject(this.project, this.customerProject))
      )
  }

  /**
   * Whenever a project is unselected, basically going back to dashboard/trello
   * screen, all items selected here should be reset.
   * It will also reset some associated services like WarningService
   *
   * IMPORTANT!! This should be the only place in which we reset all subjects.
   * @private
   */
  private saveCurrentSelectionsAndClearVariables() {
    // Save current open Project and dependencies based on unsaved changes
    this.openProjectChanges$
      .pipe(
        first(),
        // We don't care about processing responses. We are exiting Project.
        tap((changes) => {
          this.saveOpenProjectBasedOnChanges(changes, true)
            .subscribe()
        })
      )
      .subscribe(() => {
        // Reset services
        this.warningService.reset()

        // Reset subjects
        this.pProject$.next(null)
        this.pProjectFile$.next(null)
        this.pCabinets$.next([])
        this.pCustomerProject$.next(null)
        this.pCustomer$.next(null)
      })
  }

  /**
   * Recovers Customer and CustomerProject, parallel and asynchronously.
   */
  private getCustomerAndCustomerProject(project: IProject): Observable<[(Customer | undefined), CustomerProject]> {
    return forkJoin([
      project.customerId ? this.customerService.get(project.customerId) : of(null),
      this.customerService.getProject(project.customerProjectId, project.id)
    ]).pipe(
      tap((result: [(Customer | undefined), CustomerProject]) => {
        // Sometimes a Customer is deleted from "Customers" menu, but the
        // project still has its ID. This means that it will try to look
        // for it and receive nothing. Which will also prevent the ability
        // to link a new customer to the project, so we reset it.
        if (!result[0]?.id) {
          delete project.customerId
        }
      })
    )
  }

  public initialiseCabinetsGroupsList() {
    this.cabinetGroupService.getCurrentGroupsCabinetsList().subscribe(
      (groupsCabinetsList) => {
        this.pCabinetsGroups$.next(groupsCabinetsList)
      }
    )
  }
}
