import {HandleDoor} from '../handle-door'
import {
  CLASSIC_HINGES,
  DOOR_NO_DOOR_SELECTED,
  LUCKA_DJUPADAL,
  LUCKA_RONNEHOLM,
  LUCKA_SORGENFRI,
  LUCKA_SUNNANA,
  MODERN_HINGES,
  MODERN_HINGES_TAP
} from '../model-types'
import {Door} from '../door'
import {DoorType} from '../door-type'
import {Hinges} from '../hinges'
import {CenterPost} from '../center-post'

export enum TStoppersSelection {
  OurMagnetsAndStoppers = 'a',
  OnlyStoppers = 'b',
  NoMagnetsAndStoppers = 'c',
}

export class StoppersAndCatchers {
  public static select(
    doorType: DoorType,
    door: Door,
    hinges: Hinges,
    handles: HandleDoor,
    centerPost: CenterPost
  ): TStoppersSelection {
    if (
      doorType &&
      hinges &&
      (!door || door.viewOptions[0].selection !== DOOR_NO_DOOR_SELECTED)
    ) {
      const doorSelection = doorType.viewOptions[0].selection
      const hingeSelection = hinges.viewOptions[0].selection
      const hasStoppers = [LUCKA_DJUPADAL, LUCKA_RONNEHOLM, LUCKA_SUNNANA, LUCKA_SORGENFRI]
        .includes(doorSelection)

      switch (hingeSelection) {
        case CLASSIC_HINGES:
          return handles?.isVred()
            ? centerPost?.isFakeMidPost
            ? TStoppersSelection.OurMagnetsAndStoppers
            : hasStoppers ? TStoppersSelection.OnlyStoppers : TStoppersSelection.NoMagnetsAndStoppers
            : TStoppersSelection.OurMagnetsAndStoppers
        case MODERN_HINGES:
        case MODERN_HINGES_TAP:
          return hasStoppers ? TStoppersSelection.OnlyStoppers : TStoppersSelection.NoMagnetsAndStoppers
      }
    }
  }
}
