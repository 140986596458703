import {TWarningID} from '../model/warning'

import {IWarningTranslation} from './warning-types'

export const warningTexts: { [key in TWarningID]: IWarningTranslation } = {
  /* eslint-disable */
  BRASS_PLATE: {
    text: 'Too few Brass plates with logo?',
    hint: '"General rule for the Brass plate with logo: Each kitchen should have 2 pcs (a large kitchen may have 3 pcs, ' +
      'a small kitchen may have 1 pcs)"',
    detail: 'Add "Brass tags" in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  CABINET_TO_HIGH: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - is not correctly spaced from the ceiling',
    hint: undefined,
    detail: 'It should be 10 mm instead of MAX_HEIGHT',
    type: 'cabinet'
  },
  COLOR: {
    text: 'Input color number',
    hint: undefined,
    detail: 'Decide',
    type: 'project'
  },
  COMBINED_UNIT: {
    text: 'None of the cabinet are combined',
    hint: 'General rule for Combined cabinets: When two smaller cabinets, with the same depth, ' +
      'are next to each other then they can be combined. They should not be too heavy (not wider than 1500 mm for base cabinets).',
    detail: 'Add "Combined unit" in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  COUNTRY_SELECT: {
    text: 'Input the country of the customer',
    hint: undefined,
    detail: 'Choose country',
    type: 'project'
  },
  APPROXIMATE_DELIVERY: {
    text: 'Set the approximate delivery date',
    hint: undefined,
    detail: 'Set date',
    type: 'project'
  },
  DOOR_TYPE: {
    text: 'Different Style of doors',
    hint: '"General rule: A kitchen has usually the same Style of door on all cabinets, but exceptions do occur."',
    detail: 'Should it be different Styles of doors? If needed, change the doors in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  DRAWER_NARROW: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - may be too narrow for drawers with regular runners.',
    hint: undefined,
    detail: 'Opening width should be at least MINW mm – make the cabinets WIDTH mm wider. ' +
      'If this is not desireable, ask the carpentry if they can use another kind of drawer runners or solve it another way.',
    type: 'cabinet'
  },
  FRAME_FILLER: {
    text: `Cabinet nr CABINET_INDEX - CABINET_CAT- has wrong frame and/or recess on SIDE side. The recess shouldn't be larger than FRAME_SIDE (instead of FILLER_SIDE).`,
    hint: 'General rule for Recesses; The Recess can\'t be be larger than the Frame width minus 20 mm. ' +
      'For example, if the Frame is 60 mm, then the Recess can\'t be larger than 40.',
    detail: 'Change the Recess in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  HINGE_RECESS: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - has to small Recess on SIDE side because of the chosen hinges (HINGE). ' +
      'The frame widht is WIDTH mm and the Recess is RECESS mm but it should be SHOULD_BE mm.',
    hint: 'General rule for this kind of hinges: The recess should be Frame width minus 20 mm. ' +
      'For example, if the Frame width is 40 mm, then the Recess should be 20 mm.',
    detail: 'Change the Recess in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  HINGES: {
    text: 'Different Hinge types',
    hint: '"General rule: A kitchen has usually the same Hinge type on all cabinets, but exceptions do occur."',
    detail: 'Should it be different Hinge types? If needed, change the Hinges in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  LIGHTING: {
    text: 'Different Lights – spots and strips',
    hint: '"General rule: A kitchen has usually the same Light – spots or LED-strips – underneath all cabinets, but exceptions do occur."',
    detail: 'Should it be different Lights? If needed, change the Lights in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  OVEN_DOOR: {
    text: 'The oven cabinet can\'t have a door – must be a drawer instead.',
    hint: 'The "modern" hinge types won\'t fit in the narrow space underneath the oven.',
    detail: 'Change hinge type, or change door to drawer on the cabinet. Save and upload new JSON.',
    type: 'cabinet'
  },
  OVER_SIZE: {
    text: 'None of the cabinet are oversized.',
    hint: 'General rule for Oversizing: When a cabinet is next to a wall we often add Oversizing. ' +
      'The purpose is to get some margin/buffer if the dimensions differ or if the walls are not straight.',
    detail: 'If needed, add Oversizing in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  PAINT_DW: {
    text: 'There should be Painted sides on the cabinets that are next to a dishwasher',
    hint: 'Because the sides of the cabinets are visible when the dishwasher door is open, these sides should be painted.',
    detail: 'Add "Paint side because of DW" in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  PAINT_PROCESS: {
    text: 'Painting process differs in Mill vs Prodboard',
    hint: undefined,
    detail: 'Chose',
    type: 'project'
  },
  PAINT_PROCESS_SELECT: {
    text: 'Input Paint process option',
    hint: undefined,
    detail: 'Chose',
    type: 'project'
  },
  PAINT_SIDE: {
    text: 'The cabinets can\'t have painted sides',
    hint: undefined,
    detail: 'Because the painting process is "Unpainted", then the sides shouldn\'t be painted either.',
    type: 'cabinet'
  },
  PRICE: {
    text: 'Wrong price',
    hint: undefined,
    detail: 'TEXT has the wrong price on cabinet nr INDEX',
    type: 'cabinet'
  },
  RECESS: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - has the wrong Recess on SIDE side. Should be at least FRAME instead of FILLER.',
    hint: 'General rule for Recess: Width of the Frame minus 20 mm.',
    detail: 'Change the Recess in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  SCRIBINGS_CORNER_CABINET: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - is Oversized, but corner base cabinets shall never be oversized.',
    hint: 'General rule: Corner base cabinets need no oversizing, because they have already built-in margin.',
    detail: `Remove the Oversizing in Prodboard. Save and upload new JSON.`,
    type: 'cabinet'
  },
  SCRIBINGS_COVER_SIDE: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - has an Endpanel and is oversized on SIDE side. ' +
      'It shouldn\'t have both an Endpanel and oversizing – remove one of the options.',
    hint: 'General rule: A cabinet can\'t be oversized if there is an Endpanel on that side. (Exceptions may occur.)',
    detail: `Change in Prodboard. Save and upload new JSON.`,
    type: 'cabinet'
  },
  SCRIBINGS_COVER_SIDE_WALL: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - has an Endpanel and is over-sized on SIDE side. ' +
      'It shouldn\'t have an endpanel and be over-sized at the same time. Only exception: If there is an console. ' +
      'So, either remove the endpanel/oversizing, or add a console.',
    hint: 'General rule: Wall cabinets with oversizing and sidepanel should have console on panel',
    detail: `Change in Prodboard. Save and upload new JSON.`,
    type: 'cabinet'
  },
  SETTINGS_DIFF: {
    text: 'The settings differ in Mill vs Prodboard',
    hint: undefined,
    detail: 'Cabinet nr CABINET_INDEX - CABINET_CAT - has these settings that seems to have been changed: DIFFS. ' +
      'Make sure the values in Mill are correct.',
    type: 'cabinet'
  },
  SHELVES_NARROW: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - is too narrow for the pull-out shelf - MUST BE CHANGED.',
    hint: undefined,
    detail: 'Opening width should be at least 361 mm – make the cabinets WIDTH mm wider.',
    type: 'cabinet'
  },
  SPICE_RACK_NO_DOOR: {
    text: 'Cabinet nr CABINET_INDEX - CABINET_CAT - can\'t have a spice- jar rack.',
    hint: undefined,
    detail: 'The cabinet have DOOR which can\'t have a spice- jar rack.',
    type: 'cabinet'
  },
  SOCELS_DIFF: {
    text: 'Different Socle types',
    hint: '"General rule: A kitchen has usually the same Socle type on all cabinets, but exceptions do occur."',
    detail: 'Should it be different Socle types? If needed, change the Socle in Prodboard. Save and upload new JSON.',
    type: 'cabinet'
  },
  OPENING_SHOULD_BE_TALLER: {
    text: 'Cabinet CABINET_INDEX: The opening must be taller, for top hinged door',
    hint: undefined,
    detail: 'A cabinet is "top hinged", opening height should be more than 210 mm',
    type: 'cabinet',
  }
  /* eslint-enable */
}
