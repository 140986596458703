<div class="top mat-elevation-z6">
  <mat-toolbar class="header">
    <div class="logo">
      <div [routerLink]="adminHomePath" class="logo-holder">
        <img [ngSrc]="'assets/logo3.png'" alt="logo" class="logo-image"
             height="37" priority width="110">
      </div>
    </div>
    <div class="header-top">
      <div class="filler"></div>
      <kdl-floating-menu
        *ngIf="(openProjectService.project$ | async)"></kdl-floating-menu>
      <div class="filler"></div>
      <kdl-shortcuts *ngIf="userName"></kdl-shortcuts>
      <div class="filler"></div>
      <kdl-problem-list></kdl-problem-list>
      <div class="menu-holder">
        @if (!screenService.smallScreen() && authService.isAdmin()) {
          <button (click)="analytics()" color="primary" mat-icon-button>
            <mat-icon class="material-icons-outlined analytics">
              analytics
            </mat-icon>
          </button>
        }

        @if (userName) {
          <div>{{ userName }}</div>
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon class="material-icons-outlined">menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button [routerLink]="[adminHomePath]" mat-menu-item>
              <span kdlI18n label="homeMnu"></span>
            </button>

            <button *ngIf="authService.isAdmin() || authService.isCrew()"
                    [matMenuTriggerFor]="customer"
                    mat-menu-item>
              <span kdlI18n label="customersMnu"></span>
            </button>
            <button *ngIf="authService.isAdmin()" [routerLink]="[cabinetGroupPath]"
                    mat-menu-item>
              <span kdlI18n label="cabinetGroupMnu"></span>
            </button>
            <button *ngIf="authService.isAdmin()" [routerLink]="[productPath]"
                    mat-menu-item>
              <span kdlI18n label="productsMnu"></span>
            </button>
            <button *ngIf="authService.isAdmin() || authService.isCrew()"
                    [routerLink]="[userPath]"
                    mat-menu-item>
              <span kdlI18n label="usersMnu"></span>
            </button>
            <button *ngIf="authService.isAdmin()"
                    [routerLink]="[settingsHomePath]" mat-menu-item>
              <span kdlI18n label="settingsMnu"></span>
            </button>
            <button [routerLink]="['login', 'logout']" mat-menu-item>
              <span kdlI18n label="logoutMnu"></span>
            </button>
          </mat-menu>
          <mat-menu #customer>
            <button [routerLink]="[customerHomePath, 'list']" mat-menu-item>
              <span kdlI18n label="listMnu"></span>
            </button>
            <button [routerLink]="[customerHomePath]" mat-menu-item>
              <span kdlI18n label="customers"></span>
            </button>
          </mat-menu>
        }
      </div>
    </div>
  </mat-toolbar>
</div>
