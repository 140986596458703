{
  "name": "product-fe",
  "version": "1.51.2",
  "scripts": {
    "install": "cp ./package.json src/assets",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.kulladal.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "core-js": "^3.29.1",
    "ngx-editor": "^17.5.4",
    "rxjs": "~7.8.0",
    "tslib": "^2.5.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular-eslint/builder": "18.0.0",
    "@angular-eslint/eslint-plugin": "18.0.0",
    "@angular-eslint/eslint-plugin-template": "18.0.0",
    "@angular-eslint/schematics": "18.0.0",
    "@angular-eslint/template-parser": "18.0.0",
    "@angular/cli": "^18.0.0",
    "@angular/compiler-cli": "^18.0.0",
    "@angular/language-service": "^18.0.0",
    "@angular/material-luxon-adapter": "^18.0.0",
    "@kdl/kulladal-product-backend": "next",
    "@types/babel__generator": "^7",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.12.13",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "amazon-cognito-identity-js": "^6.1.2",
    "browser-sync": "^3.0.2",
    "cross-fetch": "^3.1.5",
    "eslint": "8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.2.7",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "^5.1.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.1",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-nyan-reporter": "^0.2.5",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.4.3",
    "postcss": "^8.4.21",
    "postcss-scss": "^4.0.6",
    "stylelint": "^16.6.1",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.3.0",
    "ts-node": "~10.9.2",
    "typescript": "5.4.5"
  }
}
