import {Injectable} from '@angular/core'
import {Observable, of} from 'rxjs'
import {environment} from '../../environments/environment'
import {SheetsValidationResult} from './product.service'
import {HttpClient} from '@angular/common/http'
import {map, switchMap} from 'rxjs/operators'
import {DefaultMap} from '../application/helpers'
import {SpecificCabinetType} from '../cabinet/model/cabinet-type'

@Injectable({
  providedIn: 'root'
})
export class CabinetGroupService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  validateSheets(id: string): Observable<SheetsValidationResult> {
    const url = `${environment.productUrl}/sheets/combined-group/${id}`
    return this.httpClient.get<SheetsValidationResult>(url)
  }

  installSheets(id: string) {
    const url = `${environment.productUrl}/sheets/combined-group/${id}`
    return this.httpClient.put<SheetsValidationResult>(url, {}).pipe(
      switchMap(() => this.getCurrentGroupsCabinetsList()),
      map(() => '')
    )
  }

  getCurrentGroupsCabinetsList(): Observable<DefaultMap<string, SpecificCabinetType[]>> {
    const url = `${environment.productUrl}/cabinets-groups`
    // TODO - Change this probably. Why are we checking if the first element of a collection exists? Weird.
    return this.httpClient.get(url).pipe(
      switchMap((result) => {
        if (result[0]) {
          return this.httpClient.get(url + `/${result[0].id}/${result[0].version}`).pipe(
            map((response) => this.processGroupsCabinetsList(response))
          )
        } else {
          return of()
        }
      })
    )
  }

  private processGroupsCabinetsList(result): DefaultMap<string, SpecificCabinetType[]> {
    const entries: [string, SpecificCabinetType[]][] = result.data.map(group => [
      group.group,
      group.items.map(item => {
        const processedItem: any = {}

        if (item.cabinetType !== undefined && item.cabinetType !== null) {
          processedItem.cabinetType = item.cabinetType
        }
        if (item.isMultiple !== undefined && item.isMultiple !== null) {
          processedItem.isMultiple = item.isMultiple
        }
        if (item.isIncluded !== undefined && item.isIncluded !== null) {
          processedItem.isIncluded = item.isIncluded
        }
        if (item.specificCategories && item.specificCategories.length > 0) {
          processedItem.specificCategories = item.specificCategories
        }

        return processedItem
      })
    ])
    return new DefaultMap<string, SpecificCabinetType[]>(entries, [])
  }
}
