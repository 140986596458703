import {CabinetOption, TOptionSelectName, ViewOptionType} from './cabinet-option'
import {MicroAdoptionMill} from './mill-file/mill-file-types'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {TLanguageCode} from '../application/i18n.provider'

export class MicroAdoption extends CabinetOption {
  constructor(option: MicroAdoptionMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 5
    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Built-in microwave',
        name: 'micro',
        values: [
          'Ja',
          'Nej'
        ],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'A cabinet can have a built-in microwave'
    this.title = 'optMicroAdoption'
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'MicroAdoption'
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    return [this.settingOption.getI18n('Ja', useCase, lc)]
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setYesNoActive(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  private setValuesFromProdboardData(option: MicroAdoptionMill): void {
    this.active = option.value === 'Ja'
    this.viewOptions[0].selection = option.value
  }

  // TODO: update ny info from master sheet
  private setPrice(): void {
    this.resetPrices()
    if (this.active) {
      this.price = 0
      this.labor = 0
      this.shouldHavePrice = true
    }
  }
}
