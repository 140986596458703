import {ICabinetConfig} from '../../products/cabinet-conf/model/cabinet-config'

/**
 * There are two main types of kitchen, the OG 20-talsköket and the
 * new Funkis.
 */
export declare type KitchenType = '20tal' | 'funkis'

export interface IProduct {
  /**
   * The product id.
   */
  id: string
  /**
   * The ID of the category this product belongs to.
   * It is "assumed" that it always points to the latest version.
   */
  cat: string
  /**
   * The version of the product
   */
  version: number

  /**
   * Prodboard Code
   */
  pc: string

  /**
   * Properties relating to the dimensions
   */
  // Minimum width
  wiMi?: number
  // Maximum Width
  wiMa?: number
  // Minumum Height
  heMi?: number
  /**
   * Standard height of cabinet in mm
   */
  hiSt?: number
  // Maximum height
  hiMa?: number
  // Minimum depth
  deMi?: number

  /**
   * Standard depth in mm.
   */
  deSt?: number
  // Maximum depth
  deMa?: number
  /**
   * Properties relating to cardinality (how many of each)
   */
  // Number of doors
  nuDo?: number
  // Number of drawers
  nuDr?: number
  // Number of shelves
  nuShSt?: number
  /**
   * Base price items. All base price items come in two
   * flavors, unit price and carpentry price.
   * We only put unit price here and use the iUnitPrice interface
   * to describe it further.
   */
  // Base price
  pr?: IUnitPrice
  // Extra shelves
  exShPr?: IUnitPrice
  // Higher than standard
  exPrHiSt?: IUnitPrice
  // Extra cost if deeper than standard
  exPrDeSt?: IUnitPrice
  // Cost for cover side
  enPaPr?: IUnitPrice
  // Cover back
  baPaPr?: IUnitPrice
  // Cutlery box
  cuDiPr?: IUnitPrice
  // Knife block
  knBlPr?: IUnitPrice
  // Spice holder
  waBlPr?: IUnitPrice
  // Extend drawer (utrdagslåda)
  hiDrPr?: IUnitPrice
  // Cutting board
  cuBoPr?: IUnitPrice
  // Movable shelves
  adShPr?: IUnitPrice
  // Glass door
  glDoPr?: IUnitPrice
  // No door reduction
  noDoPr?: IUnitPrice
  // Corner decoration
  cuBoCoPr?: IUnitPrice
  // Console decoration
  coDeEnPaPr?: IUnitPrice
  // Painted insides
  paInPr?: IUnitPrice
  // Lights
  liPr?: IUnitPrice
  // Built in kitchen fan
  adBuInHoPr?: IUnitPrice
  // Corner adjustments
  coSpPr?: IUnitPrice
  // Shelves inside door (Jar/Spice rack)
  shIdPr?: IUnitPrice
  // Extractor hood price with side
  csPr?: IUnitPrice
  // Extractor hood painted side.
  psPr?: IUnitPrice

  /**
   * Fittings, like hinges, knobs and handles
   */
  fittings?: IFitting
  /**
   * Front frame ...
   */
  frontFrame?: IFrontFrame
  /**
   * Openings, except the Opening height
   * all values are calculated based on
   * wiMi (minimum width) for min values and wiHi for max values
   * and the values from front frame
   */
  openings?: IOpenings

  /**
   * Socel height
   */
  shDe: number

  scSe: 'default' | 'none'

  /**
   * Recess Cabinet Type, typically base, dw, sink.
   */
  rct: string

  /**
   * Product comment in Ingles
   */
  co: string

  /**
   * Product comment in Swedish
   */
  coSe: string

  /**
   * Is Dishwasher Cabinet, a Cabinet that is really just a front
   * cover for a Dishwasher.
   */
  idc: boolean

  /**
   * Is Oven Cabinet. Is this a cabinet with adaptations for
   * ovens.
   */
  ioc: boolean
  /**
   * Type of white goods if any. Like 'ff' for freezer/fridge or o for oven.
   */
  iwg?: TWhiteGoodsTypeKey

  /**
   * Kitchen type, either "20tal" or 'funkis'
   */
  kt: KitchenType

  /**
   * Is a Base Corner Cabinet
   */
  ibcc: boolean

  /**
   * Drawer HeightS, an array of numbers (int?) for 0 - 5 drawers.
   */
  dhs: number[]

  /**
   * The time it was created
   */
  timeStamp?: number
}


export interface IOpenings {
  heHoStHe?: number

  one?: IOpeningsMinMax
  two?: IOpeningsMinMax
  three?: IOpeningsMinMax
  fourFive?: IOpeningsMinMax
}

export interface IOpeningsMinMax {
  min?: number
  max?: number
}

export interface IFrontFrame {
  // Up
  faBoFr?: number
  // Down
  faToFr?: number
  // Left
  faLeFr?: number
  // Right
  faRiFr?: number
  // Middle
  faMiFr?: number
  // Middle 3
  faMiFrThHo?: number
  // Middle 4
  faMiFrFoHo?: number
  // Middle 5 openings.
  faMiFrFiHo?: number
}

export interface IFitting {
  /**
   * Price for one hinge
   */
  hiPr?: number
  /**
   * Number of hinges
   */
  nuHi?: number
  /**
   * Number of knobs, handles
   */
  nuKn?: number
  /**
   * Hinge position
   */
  hiPo?: string
}

export interface IUnitPrice {
  /**
   * The base price for a unit. It is always present and
   * in SEK
   */
  price: number
  /**
   * Cost for manufacturing (snickeri as it says in the original)
   */
  labor: number
  /**
   * Additional material cost for the item.
   */
  material?: number
  /**
   * Reduction of prices
   */
  reduction?: number
}

export interface ProductMapItem {
  /**
   * A description in english.
   */
  description: string

  /**
   * Label in Swedish
   */
  label: string

  /**
   * Category is the main category, like "price" or "dimensions"
   */
  category: string

  /**
   * Group is used for grouping items within a category?
   */
  group?: string

  /**
   * Priority is used for ranking the individual fields within a group
   * the priority is relative to the _group_ only
   */
  priority?: number

  /**
   * The key can be included at times
   */
  key?: string
}

export const cProductMap: { [key: string]: ProductMapItem } = {
  // General
  /**
   * The category. Ex: 1.1.3. Bänkskåp med bara luckor
   */
  caLaSw: {description: 'Category', label: 'Kategori', category: 'category'},
  /**
   * The title. Ex: One door
   */
  tiLaSw: {description: 'Title', label: 'Titel', category: 'category'},
  /**
   * The product code. Ex: BD1
   */
  cat: {description: 'Product code', label: 'Produktkod', category: 'category'},
  /**
   * The description in Swedish. Ex: Bänkskåp med en lucka
   */
  deSwLaSw: {description: 'Swedish description', label: 'Beskrivning, svenska', category: 'category'},
  /**
   * The description in English. Ex: Base cabinet with one door
   */
  deEnLaSw: {description: 'English description', label: 'Engelsk beskrivning', category: 'category'},
  /**
   * If there are some comments on the specific product (in English)
   */
  co: {description: 'Comments (en)', label: 'Kommentar (en)', category: 'general'},
  /**
   * If there are some comments on the specific product (in English)
   */
  coSe: {description: 'Comments (se)', label: 'Kommentar (sv)', category: 'general'},
  /**
   * The ProdBoard code for the product. This is unique for every product
   */
  pc: {description: 'Prodboard code', label: 'Prodboard-kod', category: 'general'},
  // Dimensions
  wiMi: {
    description: 'Minimum width',
    label: 'Bredd min',
    category: 'dimensions'
  },
  wiMa: {
    description: 'Maximum width',
    label: 'Bredd max',
    category: 'dimensions'
  },
  heMi: {
    description: 'Minimum height',
    label: 'Höjd min',
    category: 'dimensions'
  },
  hiSt: {
    description: 'Standard height',
    label: 'Standardhöjd',
    category: 'dimensions'
  },
  hiMa: {
    description: 'Maximum height',
    label: 'Höjd max',
    category: 'dimensions'
  },
  deMi: {
    description: 'Minimum depth',
    label: 'Djup min',
    category: 'dimensions'
  },
  deSt: {
    description: 'Standard depth',
    label: 'Standarddjup',
    category: 'dimensions'
  },
  deMa: {
    description: 'Maximum depth',
    label: 'Djup max',
    category: 'dimensions'
  },
  // Cardinality
  nuDo: {
    description: 'Number of doors',
    label: 'Antal dörrar',
    category: 'cardinality'
  },
  nuDr: {
    description: 'Number of drawers',
    label: 'Antal lådor',
    category: 'cardinality'
  },
  nuShSt: {
    description: 'Number of shelves',
    label: 'Antal hyllplan',
    category: 'cardinality'
  },
  // Pricing items.
  pr: {
    description: 'Base price of unit',
    label: 'Grundpris',
    category: 'price'
  },
  exShPr: {
    description: 'Extra shelves',
    label: 'Extra hyllplan',
    category: 'price'
  },
  exPrHiSt: {
    description: 'Cost if higher than standard',
    label: 'Högre än standard',
    category: 'price'
  },
  exPrDeSt: {
    description: 'Cost if deeper than standard',
    label: 'Djupare än standard',
    category: 'price'
  },
  enPaPr: {
    description: 'Cover side',
    label: 'Täcksida',
    category: 'price'
  },
  baPaPr: {
    description: 'Cover side',
    label: 'Täcksida bak',
    category: 'price'
  },
  cuDiPr: {
    description: 'Cutlery box',
    label: 'Bestickinsats',
    category: 'price'
  },
  knBlPr: {
    description: 'Knife block',
    label: 'Knivblock',
    category: 'price'
  },
  waBlPr: {
    description: 'Spice holder (drawer)',
    label: 'Kryddinsats (låda)',
    category: 'price'
  },
  hiDrPr: {
    description: 'Extend drawer',
    label: 'Utrdagslåda',
    category: 'price'
  },
  cuBoPr: {
    description: 'Cutting board',
    label: 'Skärbräda',
    category: 'price'
  },
  adShPr: {
    description: 'Movable shelves',
    label: 'Flyttbara hyllplan',
    category: 'price'
  },
  glDoPr: {
    description: 'Glass doors',
    label: 'Vitrinluckor',
    category: 'price'
  },
  noDoPr: {
    description: 'No door reduction',
    label: 'Bortvalda luckor',
    category: 'price'
  },
  cuBoCoPr: {
    description: 'Corner decoration',
    label: 'Hörndekoration',
    category: 'price'
  },
  coDeEnPaPr: {
    description: 'Console decoration',
    label: 'Konsoldekoration',
    category: 'price'
  },
  paInPr: {
    description: 'Painted insides',
    label: 'Målad insida',
    category: 'price'
  },
  liPr: {
    description: 'Lights',
    label: 'Belysning',
    category: 'price'
  },
  adBuInHoPr: {
    description: 'Built in kitchen fan',
    label: 'Infälld fläkt',
    category: 'price'
  },
  coSpPr: {
    description: 'Corner adjustments',
    label: 'Anpassning för hörn',
    category: 'price'
  },
  shIdPr: {
    description: 'Jar/Spice rack inside door',
    label: 'Pris för hylla insida dörr',
    category: 'price'
  },
  csPr: {
    description: 'Carcass side with mid-panels',
    label: 'Carcass side with mid-panels',
    category: 'price'
  },
  vgPr: {
    description: 'V-groove back',
    label: 'V-groove back\n',
    category: 'price'
  },
  // Fittings.
  hiPr: {
    description: 'Price of hinges',
    label: 'Kostnad per gångjärn',
    category: 'fittings'
  },
  nuHi: {
    description: 'Number of hinges',
    label: 'Antal gångjärn',
    category: 'fittings'
  },
  nuKn: {
    description: 'Number of knobs/handles',
    label: 'Antal knoppar/handtag',
    category: 'fittings'
  },
  hiPo: {
    description: 'Hinge position',
    label: 'Gångjärnsposition',
    category: 'fittings'
  },
  // Frontframe
  faBoFr: {
    description: 'Front frame bottom',
    label: 'Frontram nertill',
    category: 'frontFrame'
  },
  faToFr: {
    description: 'Front frame up',
    label: 'Frontram upptill',
    category: 'frontFrame'
  },
  faLeFr: {
    description: 'Front frame left',
    label: 'Frontram vänster',
    category: 'frontFrame'
  },
  faRiFr: {
    description: 'Front frame right',
    label: 'Frontram höger',
    category: 'frontFrame'
  },
  faMiFr: {
    description: 'Front frame middle',
    label: 'Frontram mitten',
    category: 'frontFrame'
  },
  faMiFrThHo: {
    description: 'Front frame middle 3 openings',
    label: 'Frontram mitten (3 öppningar)',
    category: 'frontFrame'
  },
  faMiFrFoHo: {
    description: 'Front frame middle 4 openings',
    label: 'Frontram mitten (4 öppningar)',
    category: 'frontFrame'
  },
  faMiFrFiHo: {
    description: 'Front frame middle 5 openings',
    label: 'Frontram mitten (5 öppningar)',
    category: 'frontFrame'
  },
  // Openings
  heHoStHe: {
    description: 'Opening height',
    label: 'Öppningshöjd',
    category: 'openings'
  },
  miHoFiRo: {
    description: 'Min opening row 1',
    label: 'Minsta öppning rad 1',
    category: 'openings'
  },
  maHofiRo: {
    description: 'Max opening row 1',
    label: 'Stösta öppning rad 1',
    category: 'openings'
  },
  miHoSeRo: {
    description: 'Min opening row 2',
    label: 'Minsta öppning rad 2',
    category: 'openings'
  },
  maHoSeRo: {
    description: 'Max opening row 2',
    label: 'Största öppning rad 2',
    category: 'openings'
  },
  miHoThRo: {
    description: 'Min opening row 3',
    label: 'Minsta öppning rad 3',
    category: 'openings'
  },
  maHoThRo: {
    description: 'Max opening row 3',
    label: 'Största öppning rad 3',
    category: 'openings'
  },
  miHoFoFiRo: {
    description: 'Min opening row 4/5',
    label: 'Minsta öppning rad 4/5',
    category: 'openings'
  },
  maHoFoFiRo: {
    description: 'Min opening row 4,5',
    label: 'Största öppning rad 4/5',
    category: 'openings'
  }
}

/**
 * This contains all the "main" price categories that we have.
 * The main key is points to an item in the product for "price".
 * Each item in turn contains "price, labor and [material].
 *
 * The number of items tells if there is also "labor" and "material
 */
export const cUnitPrice: { [key: string]: string[] } = {
  pr: ['cuPr', 'hiPr'],
  exShPr: ['exShCuPr'],
  exPrHiSt: ['exCuPrHiSt'],
  exPrDeSt: ['exCuPrDeSt'],
  enPaPr: ['enPanCuPr'],
  baPaPr: ['baPaCuPr'],
  cuDiPr: ['cuDiCuPr', 'CuDiTy'], // Note that CuDiTy is very special
  knBlPr: ['knBlCuPr', 'knBlMaPr'],
  waBlPr: ['waBlCuPr', 'waBlMaPr'],
  hiDrPr: ['hiDrCuPr'],
  cuBoPr: ['cuBoCuPr'],
  adShPr: ['adShCuPr'],
  glDoPr: ['glDoCuPr'],
  noDoPr: ['noDoCuPr'],
  cuBoCoPr: ['cuBoCoCuPr'],
  coDeEnPaPr: ['coDeEnPaCuPr'],
  paInPr: ['paInCuPr'],
  liPr: ['liCuPr', 'liMaPr'],
  adBuInHoPr: ['adBuInHoCuPr'],
  coSpPr: ['coSpCuPr'],
  shIdPr: ['shIdCuPr'], // Shelves In Door Price
  csPr: ['csCuPr'] // Carcass side with mid-panels, panels for "Fläktkåpa"
}

export interface ProductListItem {
  /**
   * The unique product id, UUID
   */
  id: string

  /**
   * The product item version
   */
  version: number

  /**
   * Short category
   */
  cat: string

  /**
   * Prodboard Product code
   */
  pc: string

  // Beskrivning svenska, Bänkskåp med en lucka
  deSwLaSw: string

  // The title in Swedish
  tiLaSw: string

  // Short english description
  deEnLaSw: string
}

export interface Category {
  /**
   * The category id
   */
  id: string

  /**
   * Version
   */
  version: number

  /**
   * Category short name like BD1
   */
  cat: string

  /**
   * Short description in Swedish, Vägghörnskåp med en lucka
   */
  deSwLaSw: string

  /**
   * A title, again, in english but should be swedish?: "Medium, h: 710-1000"
   */
  tiLaSw: string

  /**
   * Short description in english. Wall corner cabinet, medium tall, with one door
   */
  deEnLaSw?: string

  /**
   * Date when the category version was created.
   */
  timeStamp?: number

  /**
   * Type is always C if present
   */
  type: 'C'

  /**
   * The new and future cabinet configuration
   */
  configuration: ICabinetConfig
}

export interface CategoryOptions {

  /**
   * On symmetric cabinets there are either one or two "columns"
   * Used for calculating widths of holes
   */
  columns: number[]

  rows: number[]


  heightFix: number
}

export interface IDefaultFrameAndRecessItem {
  /**
   * Bottom Frame
   */
  bf: number

  /**
   * Bottom recess
   */
  br: number

  /**
   * Top frame
   */
  tf: number

  /**
   * Top recess
   */
  tr: number
}

type TRecessCabinet =
  'base'
  | 'base-exc'
  | 'sink'
  | 'sink-out'
  | 'dw'
  | 'sit'
  | 'wall'
  | 'wall-exc'
  | 'tall'
  | 'tall-exc'
  | 'pan'
  | 'dac'

export type TRecessSkirtingType = 'standard' | 'outside'

type TRecessSkirtingValue = {
  [key in TRecessSkirtingType]: IDefaultFrameAndRecessItem
}
export type TRecess = {
  [key in TRecessCabinet]: TRecessSkirtingValue
}

export const cRecessMap: TRecess = {
  base: {
    standard: {
      bf: 20,
      br: 0,
      tf: 40,
      tr: 0
    },
    outside: {
      bf: 40,
      br: 20,
      tf: 40,
      tr: 0
    }
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'base-exc': {
    standard: {
      bf: 0,
      br: 0,
      tf: 40,
      tr: 0
    },
    outside: {
      bf: 0,
      br: 0,
      tf: 40,
      tr: 0
    }
  },
  sink: {
    standard: {
      bf: 20,
      br: 0,
      tf: 190,
      tr: 0
    },
    outside: {
      bf: 40,
      br: 20,
      tf: 190,
      tr: 0
    }
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'sink-out': {
    standard: {
      bf: 20,
      br: 0,
      tf: 40,
      tr: 10
    },
    outside: {
      bf: 40,
      br: 20,
      tf: 40,
      tr: 10
    }
  },
  dw: {
    standard: {
      bf: 20,
      br: 0,
      tf: 37,
      tr: 0
    },
    outside: {
      bf: 20,
      br: 0,
      tf: 37,
      tr: 0
    }
  },
  sit: {
    standard: {
      bf: 20,
      br: 0,
      tf: 30,
      tr: 0
    },
    outside: {
      bf: 40,
      br: 20,
      tf: 30,
      tr: 0
    }
  },
  wall: {
    standard: {
      bf: -1,
      br: 40,
      tf: -1,
      tr: 40
    },
    outside: {
      bf: -1,
      br: 40,
      tf: -1,
      tr: 40
    }
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'wall-exc': {
    standard: {
      bf: 0,
      br: 10,
      tf: 0,
      tr: 0
    },
    outside: {
      bf: 0,
      br: 10,
      tf: 0,
      tr: 0
    }
  },
  tall: {
    standard: {
      bf: 20,
      br: 0,
      tf: -1,
      tr: 0
    },
    outside: {
      bf: 40,
      br: 20,
      tf: -1,
      tr: 0
    }
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'tall-exc': {
    standard: {
      bf: 0,
      br: 0,
      tf: -1,
      tr: 0
    },
    outside: {
      bf: 0,
      br: 0,
      tf: -1,
      tr: 0
    }
  },
  pan: {
    standard: {
      bf: 20,
      br: 0,
      tf: -1,
      tr: 0
    },
    outside: {
      bf: 20,
      br: 0,
      tf: -1,
      tr: 0
    }
  },
  dac: {
    standard: {
      bf: 30,
      br: 0,
      tf: 0,
      tr: 0
    },
    outside: {
      bf: 30,
      br: 0,
      tf: 0,
      tr: 0
    }
  }
}

/**
 * "Is cabinet for white goods?
 * ff = fridge and/or freezer
 * wf = winefridge
 * o = oven
 * m = microwaveoven
 * o+m = oven and micro
 * dw = dishwasher
 * ex = extractor
 * td = tumble dryer
 * wm = washing machine"
 */
export const WhiteGoodsTypeKeys = ['ff', 'wf', 'o', 'm', 'o+m', 'dw', 'ex', 'td', 'wm'] as const
export type TWhiteGoodsTypeKey = typeof WhiteGoodsTypeKeys[number]
